<template>
  <div class="changepassword">
    <van-nav-bar
      :title="$t('CHANGEPASSWORD')"
      @click-left="onClickLeft"
    >
    <van-icon color="#fff" size="25" slot="left" name="arrow-left" />
    </van-nav-bar>
    <div class="container">
      <div class="b-b">
        <van-field v-model="phone" :placeholder="$t('INPUTPHONE')" type="number" :label="$t('CONTACTPHONE')" />
      </div>
      <div class="b-b">
        <van-field v-model="note" :placeholder="$t('INPUTNOTE')" type="number" :label="$t('NOTE')" />
      </div>
      <div class="b-b">
        <van-field :placeholder="$t('INPUTNEWSPASSWORD')" v-model="newsPassword" type="number" :label="$t('NWESPASSWORD')" />
      </div>
    </div>
    <div class="subbox">
      <van-button @click="sub" class="sub" color="#00BFFF" type="primary" block>{{$t('SUBMIT')}}</van-button>
    </div>
  </div>
</template>

<script>
import './css//my.css'
export default {
  name: 'changepassword',
  data () {
    return {
      // 手机号
      phone: null,
      // 短信
      note: null,
      // 新密码
      newsPassword: null
    }
  },
  methods: {
    onClickLeft () {
      this.$router.go(-1)
    },
    sub () {
      console.log('提交')
    }
  }
}
</script>

<style scoped>
.container {
  background-color: #fff;
  overflow: hidden;
  box-sizing: border-box;
  padding: 0 10px;
}
.b-b {
  border-bottom: 1px solid#e4e4e4;
}
.subbox {
  padding: 20px;
}
.sub {
  font-size: 18px;
}
</style>
